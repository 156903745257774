import React, { Component } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  useMap
} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';

const DEFAULT_VIEWPORT = {
  center: [0, 0],
  zoom: 2,
};

// Wrapper component to provide navigation
function WithNavigate(props) {
  const navigate = useNavigate();
  return <ChannelMapComponent {...props} navigate={navigate} />;
}

// Map events component to handle viewport changes
function MapEvents({ onViewportChanged, mapRef, groupRef, devices }) {
  const map = useMap();
  
  React.useEffect(() => {
    if (!map) return;
    
    // Handle initial bounds fitting
    const fitBoundsToMarkers = () => {
      const group = groupRef.current;
      if (group && group.getBounds && devices?.length > 0) {
        const bounds = group.getBounds();
        if (bounds.isValid()) {
          map.fitBounds(bounds, {
            padding: [50, 50],
            maxZoom: 12
          });
        }
      }
    };

    // Try multiple times to fit bounds
    const attempts = [100, 500, 1000]; // Try at 0.1s, 0.5s, and 1s
    attempts.forEach(delay => {
      setTimeout(fitBoundsToMarkers, delay);
    });
    
    // Handle viewport changes
    map.on('moveend', () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      onViewportChanged({ center: [center.lat, center.lng], zoom });
    });

    if (mapRef) {
      mapRef.current = map;
    }

    return () => {
      map.off('moveend');
    };
  }, [map, onViewportChanged, mapRef, groupRef, devices]);

  return null;
}

class ChannelMapComponent extends Component {
  state = {
    viewport: DEFAULT_VIEWPORT,
  };
  mapRef = React.createRef();
  groupRef = React.createRef();

  onViewportChanged = (viewport) => {
    this.setState({ viewport });
  };

  componentDidUpdate(oldProps) {
    if ((oldProps.devices !== this.props.devices) && 
        this.mapRef.current && 
        this.props.devices?.length > 0) {
      
      const fitBounds = () => {
        const map = this.mapRef.current;
        const group = this.groupRef.current;
        if (group && group.getBounds) {
          const bounds = group.getBounds();
          if (bounds.isValid()) {
            map.fitBounds(bounds, {
              padding: [50, 50],
              maxZoom: 12
            });
          }
        }
      };

      // Try fitting bounds immediately and after a delay
      fitBounds();
      setTimeout(fitBounds, 500);
    }
  }

  handleMarkerClick = (latitude, longitude) => {
    if (this.mapRef.current) {
      const map = this.mapRef.current;
      map.flyTo([latitude, longitude], map.getZoom(), {
        padding: [50, 50],
        maxZoom: 18
      });
    }
  }

  render() {
    const { viewport } = this.state;
    const { devices, navigate, ...rest } = this.props;

    return (
      <MapContainer
        id="map"
        center={viewport.center}
        zoom={viewport.zoom}
        animate={true}
        ref={this.mapRef}
        minZoom={2}
        maxZoom={16}
        {...rest}
      >
        <MapEvents 
          onViewportChanged={this.onViewportChanged}
          mapRef={this.mapRef}
          groupRef={this.groupRef}
          devices={devices}  // Add this prop
        />
        <TileLayer
          attribution='Tiles &copy; Esri'
          url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        />
        <FeatureGroup ref={this.groupRef}>
          {devices?.map((device) => {
            const {
              metadata: { latitude, longitude, station_id },
            } = device;
            if (!latitude || !longitude) return null;
            return (
              <Marker 
                position={[latitude, longitude]} 
                key={station_id}
                eventHandlers={{
                  click: () => this.handleMarkerClick(latitude, longitude)
                }}
              >
                <Popup>
                  <div>
                    <Header size="medium" as="span">
                      <b>Station {station_id}</b>
                    </Header>
                    <div>
                      <Icon name="map marker alternate" />
                      {latitude.toFixed(2)}, {longitude.toFixed(2)}
                    </div>
                    <br />
                    <Button
                      fluid
                      onClick={() => navigate(`/stations/${station_id}`)}
                    >
                      View Station
                    </Button>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </FeatureGroup>
      </MapContainer>
    );
  }
}

export default WithNavigate;