import { createSlice } from '@reduxjs/toolkit';

export const unitStandards = {
  us: 'us',
  metric: 'metric',
};

const unitTypes = {
  [unitStandards.us]: {
    standard: unitStandards.us,
    temperature: { value: 'F', format: '°F' },
    length: { value: 'ft', format: 'ft.' },
    speed: { value: 'knot', format: 'kn.' },
    pressure: { value: 'hPa', format: 'mbar' },
  },
  [unitStandards.metric]: {
    standard: unitStandards.metric,
    temperature: { value: 'C', format: '°C' },
    length: { value: 'm', format: 'm' },
    speed: { value: 'm/s', format: 'm/s' },
    pressure: { value: 'hPa', format: 'hPa' },
  },
};

export const units = createSlice({
  name: 'units',
  initialState: window.localStorage.getItem('unit-standard')
    ? unitTypes[window.localStorage.getItem('unit-standard')]
    : unitTypes[unitStandards.us],
  reducers: {
    setStandard: (state, action) => {  // No changes needed here
      window.localStorage.setItem('unit-standard', action.payload);
      return unitTypes[action.payload]; // Return the new state directly
    },
  },
  extraReducers: (builder) => { // Add this empty builder callback
    // No extra reducers are currently defined in your code, so this remains empty for now.
    // If you add any in the future, define them within the builder block.
},
});

export default units.reducer;
