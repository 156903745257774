import React from 'react';
import { Container, Header, Image } from 'semantic-ui-react';
import { storageUrl } from '../../firebase-config';

const FAQPageComponent = () => {
  const baseImageUrl = `${storageUrl}docimages/`;

  return (
    <Container style={{ padding: '2em' }}>
      <Header as="h1">Frequently Asked Questions</Header>

      <Header as="h2">What is mllw?</Header>
      <p>mllw stands for mean-lower-low-water. It is the long-term average of the daily lowest tide level. Tide levels are shown relative to mllw, which is the zero level by definition. Other common datums are:</p>
      <ul>
        <li>mlw (mean low water)</li>
        <li>mhhw (mean higher high water)</li>
        <li>mhw (mean high water)</li>
        <li>msl (mean sea level)</li>
      </ul>
      <p>
        For more information, see the <a href="https://tidesandcurrents.noaa.gov/datum_options.html" target="_blank" rel="noopener noreferrer">NOAA definitions</a>.
      </p>

      <Header as="h2">How do I sync Tide Alerts Calendars to my PC or phone?</Header>
      <p>
        To sync the tide calendar with your device:
      </p>
      <ol>
        <li>Navigate to the station's page and click on the "Tide Calendar" tab</li>
        <li>Click the "Sync with your calendar" button</li>
        <li>Choose your calendar application from the dropdown menu</li>
        <li>Follow your calendar app's prompts to complete the subscription</li>
      </ol>
      <p>
        Note that the Tide Alerts Calendar creates many events that you may not want to see. You can turn off the display 
        of the Tide Alerts Calendar in your calendar app if you do not want to see the events. The events will still 
        be updated in the background.
      </p>

      <Header as="h2">What do the events on the Tide Calendar mean?</Header>
      <p>Most tide calendars show the time and heights of the high and low tides each day. For some stations shallow events are also shown.  The shallow events represent when boats are likely to get stuck during low tides at locations near the tide gauge.</p>
      <Image src={`${baseImageUrl}shallowcalendarevent.jpg`} alt="Shallow Events" />
      <Header as="h2">How far away from the Tide Station are the forecasts valid for?</Header>
      <p>Tide levels and forecasts are typically very local.  For example, in a bay with a narrow inlet the tides inside the bay are likely very different to the tides in the ocean outside the bay. Different bays close by are also likely to have different tides because the tides are highly dependent on the size of the inlet and the depth, size, and shape of the bay. Long narrow bays will also have different tides at different parts of the bay.</p>
      <Header as="h2">Why do the Observed and Predicted values sometimes differ?</Header>
      <p>Wind speed and direction can have a major effect on actual water levels.  Winds can force water levels higher or lower depending on the direction of the wind.  Wind driven higher levels are often referred to as storm surge and can cause flooding.  If a bay freezes during the winter, ice can cause the water levels to be consistently lower than normal.</p>
      <Header as="h2">Are these Predictions accurate?</Header>
      <p>Tide alerts predictions use the same techniques that NOAA uses and are typically more accurate than NOAA predictions at subordinate locations. Subordinate stations are historic stations which do not have tidal harmonic constants available. Tide predictions for subordinate stations are generated by first generating high/low tide predictions for a designated harmonic station, called the “reference station”; then time and height adjustments are applied to correct the high/low predictions to the tidal conditions at the subordinate station. Subordinate stations only allow for the generation of high/low tide predictions.  Tide Alerts stations are typically located where NOAA does not have harmonic stations or does not provide predictions</p>
      {/* Add more questions and answers as needed */}
    </Container>
  );
};

export default FAQPageComponent;
