import React from 'react';
import { Menu, Container, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../graphics/logo_nobackground.svg';

const NavbarComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (location.pathname.includes('/stations/')) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return (
    <NavbarMenu inverted attached="bottom">
      <Container>
        {(location.pathname === '/faq' || location.pathname.includes('/stations/')) && (
          <Menu.Item onClick={handleBackClick}>
            <Icon name="arrow left" />
          </Menu.Item>
        )}
        <Menu.Item to="/" as={Link} header>
          <img src={logo} alt="Tide Alerts" style={{ width: '8em' }} />
        </Menu.Item>
      </Container>
    </NavbarMenu>
  );
};

const NavbarMenu = styled(Menu)`
  font-size: 1.2em !important;
  margin-bottom: 0 !important;
  background: #115c97ff !important;
`;

export default NavbarComponent;
