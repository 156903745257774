import React from 'react';
import { Table } from 'semantic-ui-react';

const MetadataTableComponent = ({ metadata, ...rest }) => (
  <Table definition color="black" unstackable {...rest}>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Name</Table.Cell>
        <Table.Cell>{metadata.name}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Established</Table.Cell>
        <Table.Cell>
          {new Date(
            metadata.created_at.slice(0, metadata.created_at.indexOf('.'))
          ).toLocaleDateString([], {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Latitude</Table.Cell>
        <Table.Cell>{Number(metadata.latitude).toFixed(5)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Longitude</Table.Cell>
        <Table.Cell>{Number(metadata.longitude).toFixed(5)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Level sensor type</Table.Cell>
        <Table.Cell>{metadata.type}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default MetadataTableComponent;
