import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import TideChartComponent from '../charts/TideChartComponent';
import {
  Container,
  Segment,
  Dropdown,
  Select,
  Tab,
  Grid,
  Item,
  Header,
} from 'semantic-ui-react';
import TideTableComponent from '../station/TideTableComponent';
import MonthPickerComponent from '../MonthPickerComponent';
import MetadataTableComponent from '../station/MetadataTableComponent';
import StatisticsTableComponent from '../station/StatisticsTableComponent';
import VitalsTableComponent from '../station/VitalsTableComponent';
import DailyTidesTableComponent from '../station/DailyTidesTableComponent';
import LatestReadingStatComponent from '../station/LatestReadingStatComponent';
import WindChartComponent from '../charts/WindChartComponent';
import LineChartComponent from '../charts/LineChartComponent';
import { unitStandards } from '../../reducers/unitSlice';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import MultilineChartComponent from '../charts/MultilineChartComponent';
// import CalendarThresholdComponent from '../station/CalendarThresholdComponent';
import TideCalendarComponent from '../station/TideCalendarComponent';
import Cookies from 'js-cookie';
import { storageUrl } from '../../firebase-config'
import logo from '../../graphics/logo-192x192.png'; // Import the PNG logo
import { AddToCalendarButton } from 'add-to-calendar-button-react';

// Wrapper component to provide route params
function WithRouteParams(props) {
  const params = useParams();
  return <StationPageComponent {...props} routeParams={params} />;
}

class StationPageComponent extends Component {
  state = {
    month: new Date(),
  };

  downloadAsPDF = () => {
    const { routeParams: { stationId }, metadata } = this.props;
    const doc = new jsPDF();
    const dateString = this.state.month.toLocaleDateString('default', {
      month: 'short',
      year: 'numeric',
    });

    // Add logo and header
    const img = new Image();
    img.src = logo;
    doc.addImage(img, 'PNG', 14, 10, 15, 15);
    doc.setFontSize(16);
    doc.text(`Tide Alerts Station ${stationId}: ${metadata.name}`, 34, 20);

    doc.autoTable({ html: '#tide-table', startY: 30 });
    doc.save(`${dateString} Tide Table - Station ${stationId}.pdf`);
  };

  componentDidMount() {
    const { routeParams: { stationId }, 
      getStationMetadata,
      getStationDatum,
      getStationStatistics,
      getStationMeasurements,
      getStationForecast,
      getStationForecastHighsAndLows,
      getStationMetData,
      getStationTideThreshold
    } = this.props;

    Cookies.set('lastVisitedStation', stationId);

    const start = new Date();
    start.setHours(0, 0, 0);
    start.setDate(start.getDate() - 1);
    const end = new Date();
    end.setHours(0, 0, 0);
    end.setDate(end.getDate() + 2);

    // Call APIs with stationId
    getStationMetadata(stationId);
    getStationDatum(stationId);
    getStationStatistics(stationId);
    getStationMeasurements(stationId, start, end);
    getStationForecast(stationId, start, end);
    getStationForecastHighsAndLows(stationId);
    getStationMetData(stationId, start, end);
    getStationTideThreshold(stationId);
  }

  componentDidUpdate(oldProps, oldState) {
    const { routeParams: { stationId }, metadata, units, threshold } = this.props;

    if (metadata !== oldProps.metadata) {
      this.props.getStationVitals(stationId);
    }
    if (
      (units !== oldProps.units ||
        threshold !== oldProps.threshold) &&
      threshold !== null
    ) {
      const unit = units.standard;
      this.props.getStationTideCalendar(
        stationId,  // Add stationId as first parameter
        unit,
        threshold[unit],
        threshold.isLow
      );
    }
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert('URL copied to clipboard');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  render() {
    const {
      tidesToday,
      tidesTomorrow,
      latestMeasurement,
      latestMetData,
      measurements,
      met,
      forecast,
      tableData,
      routeParams: { stationId },
      metadata,
      units,
      setUnit,
      statistics,
      vitals,
      tideCalendar,
      threshold,
    } = this.props;
    const { month } = this.state;
    let nextTideTodayIndex;
    let nextTideTomorrowIndex;
    let filteredTableData;

    if (tidesToday) {
      tidesToday.sort((a, b) => a.time - b.time);
      tidesTomorrow.sort((a, b) => a.time - b.time);

      const time = new Date().getTime() / 1000;
      for (let i = 0; i < tidesToday.length; i++) {
        if (
          tidesToday[i].time > time &&
          new Date(tidesToday[i].time * 1000).getDate() === new Date().getDate()
        ) {
          nextTideTodayIndex = i;
          break;
        }
      }
      for (let i = 0; i < tidesTomorrow.length; i++) {
        if (tidesTomorrow[i].time > time && !nextTideTodayIndex) {
          nextTideTomorrowIndex = i;
          break;
        }
      }
    }

    if (tableData) {
      filteredTableData = Object.values(tableData).filter(
        ({ date }) =>
          date.getMonth() === month.getMonth() &&
          date.getYear() === month.getYear()
      );
    }

    const filteredMetData = {};
    if (met) {
      filteredMetData.windDir = met.windDir.filter(
        (item) => new Date(item.x).getMinutes() % 5 === 0
      );
      filteredMetData.windSpeed = met.windSpeed.filter(
        (item) => new Date(item[0]).getMinutes() % 5 === 0
      );
      filteredMetData.gust = met.gust.filter(
        (item) => new Date(item[0]).getMinutes() % 5 === 0
      );
    }

    const calendarUrl = `${storageUrl}calendars/${stationId}/calendar.ics`;
    const imageurl = `${storageUrl}images/${stationId}.jpg`;


    return (
      <Container style={{ flexGrow: 1 }}>
        {stationId && metadata && (
          <div style={{ padding: '1.5em 0' }}>
            <Item.Group>
              <Item>
                <Item.Image
                  src = {imageurl}
                  //src={`https://github.com/mawrob/images/blob/master/${stationId}.jpg?raw=true`}
                  size="small"
                />
                <Item.Content>
                  <Item.Header>
                    <h1>Station {stationId}</h1>
                  </Item.Header>
                  <Item.Meta>{metadata.name}</Item.Meta>
                  <Item.Description>
                    <Select
                      options={[
                        {
                          key: unitStandards.metric,
                          value: unitStandards.metric,
                          text: 'Metric units',
                        },
                        {
                          key: unitStandards.us,
                          value: unitStandards.us,
                          text: 'US units',
                        },
                      ]}
                      value={units.standard}
                      onChange={(e, d) => setUnit(d.value)}
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        )}

        <Grid columns={'equal'} stackable>
          <Grid.Column>
            {metadata && <MetadataTableComponent metadata={metadata} />}
          </Grid.Column>
          <Grid.Column>
          <Tab
            panes={[
              {
                menuItem: 'Statistics',
                render: () => (
                  <Tab.Pane> 
                    <StatisticsTableComponent
                      statistics={statistics}
                      unit={units.length.format}
                    />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: 'Vitals',
                render: () => (
                  <Tab.Pane>
                    <VitalsTableComponent 
                      vitals={vitals} 
                    />
                  </Tab.Pane>
                ),
              },
            ]}
          />
          </Grid.Column>
        </Grid>

        <Grid columns="equal">
          <Grid.Column only="tablet computer">
            {tidesToday?.length > 0 && (
              <DailyTidesTableComponent
                title="Tides Today"
                tides={tidesToday}
                nextTideIndex={nextTideTodayIndex}
                unit={units.length.format}
              />
            )}
          </Grid.Column>
          <Grid.Column only="tablet computer">
            {tidesTomorrow?.length > 0 && (
              <DailyTidesTableComponent
                title="Tides Tomorrow"
                tides={tidesTomorrow}
                nextTideIndex={nextTideTomorrowIndex}
                unit={units.length.format}
              />
            )}
          </Grid.Column>
          <Grid.Column only="mobile">
            {tidesToday?.length > 0 && tidesTomorrow?.length > 0 && (
              <Tab
                panes={[
                  {
                    menuItem: 'Tides Today',
                    render: () => (
                      <Tab.Pane as="div">
                        <DailyTidesTableComponent
                          headless
                          tides={tidesToday}
                          nextTideIndex={nextTideTodayIndex}
                          unit={units.length.format}
                        />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Tides Tomorrow',
                    render: () => (
                      <Tab.Pane as="div">
                        <DailyTidesTableComponent
                          headless
                          tides={tidesTomorrow}
                          nextTideIndex={nextTideTomorrowIndex}
                          unit={units.length.format}
                        />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            )}
          </Grid.Column>
        </Grid>

        <Grid stackable columns="equal" divided="vertically">
          <Grid.Row style={{ marginTop: '1rem' }}>
            <Grid.Column
              largeScreen={4}
              computer={5}
              tablet={6}
              textAlign="right"
            >
              {latestMeasurement && (
                <LatestReadingStatComponent
                  title="Observed Level"
                  value={latestMeasurement.value}
                  unit={units.length.format}
                  time={latestMeasurement.time}
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <TideChartComponent
                data={{ measurements, forecast }}
                height={300}
                unit={units.length.format}
                title={`Water Level`}
              />
            </Grid.Column>
          </Grid.Row>
          {latestMetData && (
            <>
              {!!latestMetData.wind_speed && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Wind Speed"
                        value={latestMetData.wind_speed}
                        unit={units.speed.format}
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <WindChartComponent
                      data={{
                        windDir: filteredMetData?.windDir,
                        windSpeed: filteredMetData?.windSpeed,
                        gust: filteredMetData?.gust,
                      }}
                      height={300}
                      title={`Wind`}
                      unit={units.speed.format}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {(latestMetData.water_temp || latestMetData.air_temp) && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <>
                        {!!latestMetData.water_temp && (
                          <LatestReadingStatComponent
                            title="Water Temperature"
                            value={latestMetData.water_temp}
                            unit={units.temperature.format}
                            time={latestMetData.time}
                          />
                        )}
                        {!!latestMetData.air_temp && (
                          <LatestReadingStatComponent
                            title="Air Temperature"
                            value={latestMetData.air_temp}
                            unit={units.temperature.format}
                            time={latestMetData.time}
                          />
                        )}
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <MultilineChartComponent
                      data={[met?.waterTemp, met?.airTemp]}
                      height={350}
                      title="Temperature"
                      yAxis="Temperature"
                      dataLabel={['Water Temperature', 'Air Temperature']}
                      units={[
                        units.temperature.format,
                        units.temperature.format,
                      ]}
                      lines={2}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {!!latestMetData.humidity && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Humidity"
                        value={latestMetData.humidity}
                        unit="%"
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <LineChartComponent
                      data={met?.humidity}
                      height={300}
                      title="Humidity"
                      yAxis="Humidity"
                      dataLabel="Humidity"
                      unit="%"
                      yCeiling={100}
                      yFloor={0}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {!!latestMetData.pressure && (
                <Grid.Row>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    textAlign="right"
                  >
                    {latestMetData && (
                      <LatestReadingStatComponent
                        title="Pressure"
                        value={latestMetData.pressure}
                        unit={units.pressure.format}
                        time={latestMetData.time}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <LineChartComponent
                      data={met?.pressure}
                      height={300}
                      title="Atmospheric Pressure"
                      yAxis="Atmospheric Pressure"
                      dataLabel="Atmospheric Pressure"
                      unit={units.pressure.format}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </>
          )}
        </Grid>

        <Tab
          panes={[
            {
              menuItem: 'Tide Table',
              render: () => (
                <Tab.Pane loading={!filteredTableData}>
                    <Segment vertical style={{ paddingTop: 0 }}>
                    <MonthPickerComponent
                      onChange={this.handleMonthSelection}
                    />
                    <Dropdown
                      text="Download"
                      style={{
                        padding: '0.5em',
                      }}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          text="PDF"
                          onClick={this.downloadAsPDF}
                        />
                        {/* <Dropdown.Item text="CSV" /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    {filteredTableData?.length > 0 ? (
                      <TideTableComponent
                        unit={units.length.format}
                        data={filteredTableData}
                        id="tide-table"
                      />
                    ) : (
                      <Header textAlign="center">No data available</Header>
                    )}
                  </Segment>
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Tide Calendar',
              render: () => (
                <Tab.Pane 
                  loading={tideCalendar === undefined && threshold !== null}
                  disabled={threshold === null}
                >
                  <div style={{ flexShrink: 0, marginBottom: '1rem', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <AddToCalendarButton
                      name={`Tide Alerts for ${metadata?.name || `Station ${stationId}`}`}
                      description={`Tide threshold alerts for ${metadata?.name || `Station ${stationId}`}`}
                      startDate="today"
                      endDate="2025-12-31"
                      timeZone="America/New_York"
                      subscribe={true}
                      label="Sync with your calendar"
                      icsFile={calendarUrl}
                      options={[
                        'Apple',
                        'Google',
                        'iCal',
                        'Microsoft365',
                        'MicrosoftTeams',
                        'Outlook.com',
                        'Yahoo'
                      ]}
                      buttonStyle="default"
                      listStyle="overlay"
                      lightMode="light"
                      size="3"
                    />
                  </div>
                  <div style={{ flexGrow: 1, overflowY: 'scroll', width: '100%', paddingRight: 0 }}>
                    <TideCalendarComponent
                      data={tideCalendar}
                      style={{ height: '100%', width: '100%', paddingRight: 0 }}
                    />
                  </div>
                </Tab.Pane>
              ),
            },
          ]}
        />
        <br />
      </Container>
    );
  }

  handleMonthSelection = (month) => {
    this.setState({ month });
  };
}

// Export the wrapped component
export default WithRouteParams;
