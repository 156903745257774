import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import HomePageController from './controllers/HomePageController';
import StationPageController from './controllers/StationPageController';
import FAQPageComponent from './pages/FAQPageComponent';
import NavbarComponent from './NavbarComponent';
import FooterComponent from './FooterComponent';
import PageNotFoundComponent from './pages/PageNotFoundComponent';

const AppRoutes = () => {
  const [initialRedirect, setInitialRedirect] = useState(false);
  const lastVisitedStation = Cookies.get('lastVisitedStation');

  useEffect(() => {
    const hasRedirected = sessionStorage.getItem('hasRedirected');
    if (!hasRedirected && lastVisitedStation && lastVisitedStation !== 'undefined') {
      setInitialRedirect(true);
      sessionStorage.setItem('hasRedirected', 'true');
    }
  }, [lastVisitedStation]);

  useEffect(() => {
    if (initialRedirect) {
      setInitialRedirect(false);
    }
  }, [initialRedirect]);

  return (
    <BrowserRouter>
      <NavbarComponent />
      <Routes>
        {initialRedirect ? (
          <Route path="/" element={<Navigate to={`/stations/${lastVisitedStation}`} replace />} />
        ) : (
          <Route path="/" element={<HomePageController />} />
        )}
        <Route path="/stations/:stationId" element={<StationPageController />} />
        <Route path="/faq" element={<FAQPageComponent />} />
        <Route path="*" element={<PageNotFoundComponent />} />
      </Routes>
      <FooterComponent />
    </BrowserRouter>
  );
};

export default AppRoutes;
