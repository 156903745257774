import React from 'react';
import { Table } from 'semantic-ui-react';

const StatisticsTableComponent = ({ statistics, unit }) => (
  <Table
    unstackable
    compact
    definition
    basic="very"
  >
    <Table.Body>
      {statistics?.waterMax && (
        <Table.Row>
          <Table.Cell>Water Level Max (rel MHHW)</Table.Cell>
          <Table.Cell>
            {statistics.waterMax.level} {unit}
            {', '}
            {statistics.waterMax.time.toLocaleDateString('default', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </Table.Cell>
        </Table.Row>
      )}
      {statistics?.waterMin && (
        <Table.Row>
          <Table.Cell>Water Level Min (rel MLLW)</Table.Cell>
          <Table.Cell>
            {statistics.waterMin.level} {unit}
            {', '}
            {statistics.waterMin.time.toLocaleDateString('default', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </Table.Cell>
        </Table.Row>
      )}
      {statistics?.msl && (
        <Table.Row>
          <Table.Cell>Mean Sea Level</Table.Cell>
          <Table.Cell>
            {statistics.msl} {unit}
          </Table.Cell>
        </Table.Row>
      )}
      {statistics?.mhhw && (
        <Table.Row>
          <Table.Cell>Mean Higher High Water</Table.Cell>
          <Table.Cell>
            {statistics.mhhw} {unit}
          </Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

export default StatisticsTableComponent;
