import React from 'react';
import moment from 'moment';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const MultilineChartComponent = (props) => {
  const options = {
    chart: {
      zoomType: 'x',
      panning: true,
      panKey: 'shift',
      height: props.height,
    },
    title: {
      text: props.title,
      useHTML: true,
    },
    xAxis: {
      type: 'datetime',
      plotLines: [
        {
          value: new Date(),
          label: 'Current time',
          dashStyle: 'dash',
        },
      ],
    },
    yAxis: {
      title: {
        text: props.yAxis,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {},
    time: {
      useUTC: false,
      moment: moment,
    },
    accessibility: {
      enabled: false,
    },
    series: [],
  };

  for (let i = 0; i < props.lines; ++i) {
    options.series.push({
      type: 'line',
      name: props.dataLabel[i],
      data: props.data[i],
      tooltip: {
        valueSuffix: props.units[i],
      },
    });
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default MultilineChartComponent;
