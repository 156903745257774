import React from 'react';
import { Table, Grid } from 'semantic-ui-react';

const TideTableComponent = ({ unit, data, ...rest }) => {
  const sortedData = data.sort((a, b) => a.date > b.date);

  const formatTime = (time, locale, sub) => {
    const t = time?.toLocaleString(locale ? locale : 'default', {
      hour: 'numeric',
      minute: '2-digit',
    });

    if (sub && t) {
      return t.substring(0, t.indexOf(' '));
    } else return t;
  };

  return (
    <Grid as="div" columns={1}>
      <Grid.Column only="tablet computer" style={{ paddingBottom: 0 }}>
        <Table definition striped {...rest} singleLine>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell rowSpan="2"></Table.HeaderCell>
              <Table.HeaderCell colSpan="4">Low Tide</Table.HeaderCell>
              <Table.HeaderCell colSpan="4">High Tide</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>AM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
              <Table.HeaderCell>PM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
              <Table.HeaderCell>AM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
              <Table.HeaderCell>PM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedData.map((day) => {
              return (
                <Table.Row key={day.date}>
                  <Table.Cell>
                    {day.date.toLocaleString('default', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      weekday: 'short',
                    })}
                  </Table.Cell>
                  <Table.Cell>{formatTime(day.lowTides.am?.time)}</Table.Cell>
                  <Table.Cell>{day.lowTides.am?.value.toFixed(2)}</Table.Cell>
                  <Table.Cell>{formatTime(day.lowTides.pm?.time)}</Table.Cell>
                  <Table.Cell>{day.lowTides.pm?.value.toFixed(2)}</Table.Cell>
                  <Table.Cell>{formatTime(day.highTides.am?.time)}</Table.Cell>
                  <Table.Cell>{day.highTides.am?.value.toFixed(2)}</Table.Cell>
                  <Table.Cell>{formatTime(day.highTides.pm?.time)}</Table.Cell>
                  <Table.Cell>{day.highTides.pm?.value.toFixed(2)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column only="mobile" style={{ paddingBottom: 0 }}>
        <Table unstackable {...rest} id="">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>
                {sortedData[0].date.toLocaleDateString('default', {
                  year: 'numeric',
                  month: 'short',
                })}
              </Table.HeaderCell>
              <Table.HeaderCell>Tide</Table.HeaderCell>
              <Table.HeaderCell>AM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
              <Table.HeaderCell>PM</Table.HeaderCell>
              <Table.HeaderCell>{unit}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedData.map((day) => {
              return (
                <React.Fragment key={day.date}>
                  <Table.Row>
                    <Table.Cell rowSpan={2}>
                      {day.date.toLocaleDateString('default', {
                        weekday: 'short',
                        day: 'numeric',
                      })}
                    </Table.Cell>
                    <Table.Cell>Low</Table.Cell>
                    <Table.Cell>
                      {formatTime(day.lowTides.am?.time, 'en-US', true)}
                    </Table.Cell>
                    <Table.Cell>{day.lowTides.am?.value.toFixed(2)}</Table.Cell>
                    <Table.Cell>
                      {formatTime(day.lowTides.pm?.time, 'en-US', true)}
                    </Table.Cell>
                    <Table.Cell>{day.lowTides.pm?.value.toFixed(2)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>High</Table.Cell>
                    <Table.Cell>
                      {formatTime(day.highTides.am?.time, 'en-US', true)}
                    </Table.Cell>
                    <Table.Cell>
                      {day.highTides.am?.value.toFixed(2)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatTime(day.highTides.pm?.time, 'en-US', true)}
                    </Table.Cell>
                    <Table.Cell>
                      {day.highTides.pm?.value.toFixed(2)}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
};

export default TideTableComponent;
