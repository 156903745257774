import React, { Component } from 'react';
import styled from 'styled-components';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Routes from './Routes';
import { Message, Image } from 'semantic-ui-react';
import shareButton from '../graphics/iphoneshare.png';
import { connect } from 'react-redux';

class App extends Component {
  state = {
    showInstallMessage: false,
  };

  componentDidMount() {
    // Detects if device is on iOS or iPadOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent) || (userAgent.includes('mac') && 'ontouchend' in document);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    const iosDetected = isIos();
    const standaloneModeDetected = isInStandaloneMode();

    // Checks if should display install popup notification
    if (iosDetected && !standaloneModeDetected) {
      setTimeout(() => {
        this.setState({ showInstallMessage: true });
      }, 100); // Add a small delay to ensure correct re-rendering
    }
  }

  render() {
    const { showInstallMessage } = this.state;

    return (
      <StyledApp>
        {showInstallMessage && (
          <StyledMessage
            info
            onDismiss={() => this.setState({ showInstallMessage: false })}
            header="Install Tide Alerts"
            content={
              <div>
                To install Tide Alerts on your phone: tap <Image src={shareButton} inline size="mini" /> in your menu and then click <b>Add to Home Screen</b>.
              </div>
            }
          />
        )}
        <Routes />
      </StyledApp>
    );
  }
}

const StyledApp = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledMessage = styled(Message)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999; /* Ensure the modal is on top of other elements */
  background-color: #fff; /* Ensure the background color is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better visibility */
`;

export default connect()(App);
