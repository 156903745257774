// eslint-disable-next-line
// const devConfig = {
//   firebaseUrl: 'https://tide-alerts-dev.firebaseio.com/',
//   functionUrl: 'https://us-central1-tide-alerts-dev.cloudfunctions.net/',
//   storageUrl: 'https://storage.googleapis.com/tide-alerts-dev.appspot.com/',
//   webcalUrl:  'webcals://storage.googleapis.com/tide-alerts-dev.appspot.com/',
//   webcalRedirectUrl: 'https://tide-alerts-dev.web.app/webcal/',
//   googleCalendarUrl: 'https://calendar.google.com/calendar/r/settings/addbyurl?url='
// };

// eslint-disable-next-line
const prodConfig = {
  firebaseUrl: 'https://tide-alerts.firebaseio.com/',
  functionUrl: 'https://us-central1-tide-alerts.cloudfunctions.net/',
  storageUrl: 'https://storage.googleapis.com/tide-alerts.appspot.com/',
  //webcalUrl:  'webcals://storage.googleapis.com/tide-alerts.appspot.com/',
  //webcalRedirectUrl: 'https://app.tidealerts.com/webcal/',  // Fix domain name to include 's'
  //googleCalendarUrl: 'https://calendar.google.com/calendar/r/settings/addbyurl?url='
};

// This is not working. Change manually.
//const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
const config = prodConfig;


export const { firebaseUrl, functionUrl, storageUrl, webcalUrl, webcalRedirectUrl, googleCalendarUrl } = config;
