import React from 'react';
import { Table, Header } from 'semantic-ui-react';

const DailyTidesTableComponent = ({
  title,
  tides,
  unit,
  nextTideIndex,
  headless,
  ...rest
}) => (
  <Table
    definition
    color="blue"
    unstackable
    {...rest}
    attached={headless ? 'bottom' : undefined}
  >
    {!headless && (
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>
            <Header>{title}</Header>
          </Table.HeaderCell>
          <Table.HeaderCell>Tide</Table.HeaderCell>
          <Table.HeaderCell>{unit}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    )}
    <Table.Body>
      {tides.length > 0 &&
        tides.map((tide, index) => (
          <Table.Row key={tide.time} active={index === nextTideIndex}>
            <Table.Cell>
              {new Date(tide.time * 1000).toLocaleString('default', {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </Table.Cell>
            <Table.Cell style={{ textTransform: 'capitalize' }}>
              {tide.type}
              {headless ? ' tide' : ''}
            </Table.Cell>
            <Table.Cell>
              {Number(tide.level).toFixed(2)}
              {headless ? unit : ''}
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
  </Table>
);

export default DailyTidesTableComponent;
