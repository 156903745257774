import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllStations,
  getStationMeasurements,
  getStationMetadata,
  getStationForecast,
  getStationForecastHighsAndLows,
  getStationStatistics,
  getStationVitals,
  getStationMetData,
  getStationDatum,
  getStationTideCalendar,
  getStationTideThreshold as getStationTideCalendarThreshold,
} from '../services/TideStationService';
import { unitStandards } from './unitSlice';

export const fetchAllStations = createAsyncThunk(
  'stations/fetch/all',
  async () => {
    const response = await getAllStations();
    return response;
  }
);

export const fetchStationMeasurements = createAsyncThunk(
  'stations/fetch/measurements',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationMeasurements(stationId, start, end);
    return response;
  }
);

export const fetchStationMetData = createAsyncThunk(
  'stations/fetch/meteorological',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationMetData(stationId, start, end);
    return response;
  }
);

export const fetchStationForecast = createAsyncThunk(
  'stations/fetch/forecast',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationForecast(stationId, start, end);
    return response;
  }
);

export const fetchStationForecastHighsAndLows = createAsyncThunk(
  'stations/fetch/forecastHighsAndLows',
  async ({ stationId, start, end }, thunkApi) => {
    const response = await getStationForecastHighsAndLows(
      stationId,
      start,
      end
    );
    return response;
  }
);

export const fetchStationDatum = createAsyncThunk(
  'stations/fetch/datum',
  async (stationId, thunkApi) => {
    const response = await getStationDatum(stationId);
    return response;
  }
);

export const fetchStationMetadata = createAsyncThunk(
  'stations/fetch/metadata',
  async (stationId, thunkApi) => {
    const response = await getStationMetadata(stationId);
    return response;
  }
);

export const fetchStationStatistics = createAsyncThunk(
  'stations/fetch/statistics',
  async (stationId, thunkApi) => {
    const response = await getStationStatistics(stationId);
    return response;
  }
);

export const fetchStationVitals = createAsyncThunk(
  'stations/fetch/vitals',
  async ( stationId , thunkApi) => {
    const response = await getStationVitals(stationId);
    return response;
  }
);

export const fetchStationTideCalendar = createAsyncThunk(
  'stations/fetch/tideCalendar',
  async ({ stationId, unit, isLow, threshold }, thunkApi) => {
    const response = await getStationTideCalendar(
      stationId,
      unit,
      isLow,
      threshold
    );
    return response;
  }
);

export const fetchStationTideCalendarThreshold = createAsyncThunk(
  'stations/fetch/tideCalendarThreshold',
  async ({ stationId }, thunkApi) => {
    const response = await getStationTideCalendarThreshold(stationId);
    return response;
  }
);

const stationListSlice = createSlice({
  name: 'stations',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStations.fulfilled, (state, action) => {
      for (const [stationId, metadata] of Object.entries(action.payload)) {
          state[stationId] = { ...state[stationId], metadata }; // No need for the if/else, the spread operator handles undefined
        }
      })
      .addCase(fetchAllStations.rejected, (state, action) => {
        console.error('fetchAllStations rejected:', state, action); // Improved error logging
      })
      .addCase(fetchStationMeasurements.fulfilled, (state, action) => {
      const { stationId } = action.meta.arg;
        state[stationId] = { ...state[stationId], measurements: action.payload };
      })
      .addCase(fetchStationMeasurements.rejected, (state, action) => {
        console.error('fetchStationMeasurements rejected:', state, action);
      })
      .addCase(fetchStationMetData.fulfilled, (state, action) => {
      const { stationId } = action.meta.arg;
        state[stationId] = { ...state[stationId], met: action.payload };
      })
      .addCase(fetchStationMetData.rejected, (state, action) => {
        console.error('fetchStationMetData rejected:', state, action);
      })
      .addCase(fetchStationForecast.fulfilled, (state, action) => {
      const { stationId } = action.meta.arg;
      if (!action.payload || Object.keys(action.payload).length === 0) {
          return; // Just return; no need for return state;
        }
        state[stationId] = { ...state[stationId], forecast: action.payload };
      })
      .addCase(fetchStationForecast.rejected, (state, action) => {
        console.error('fetchStationForecast rejected:', state, action);
      })
      .addCase(fetchStationForecastHighsAndLows.fulfilled, (state, action) => {
      const { stationId } = action.meta.arg;
      if (Object.keys(action.payload).length === 0) {
          return;  // No need to update state if payload is empty
        }
        state[stationId] = {
          ...state[stationId],
          forecastHighs: { ...state[stationId]?.forecastHighs, ...action.payload.forecastHighs },
          forecastLows: { ...state[stationId]?.forecastLows, ...action.payload.forecastLows },
          };
      })
      .addCase(fetchStationForecastHighsAndLows.rejected, (state, action) => {
        console.error('fetchStationForecastHighsAndLows rejected:', state, action);
      })
      .addCase(fetchStationDatum.fulfilled, (state, action) => {
      const stationId = action.meta.arg;
        state[stationId] = { ...state[stationId], datum: action.payload };
      })
      .addCase(fetchStationMetadata.fulfilled, (state, action) => {
      const stationId = action.meta.arg;
        state[stationId] = { ...state[stationId], metadata: action.payload };
      })
      .addCase(fetchStationMetadata.rejected, (state, action) => {
        console.error('fetchStationMetadata rejected:', state, action);
      })
      .addCase(fetchStationStatistics.fulfilled, (state, action) => {
      const stationId = action.meta.arg;
        state[stationId] = { ...state[stationId], statistics: action.payload };
      })
      .addCase(fetchStationStatistics.rejected, (state, action) => {
        console.error('fetchStationStatistics rejected:', state, action);
      })
      .addCase(fetchStationVitals.fulfilled, (state, action) => {
      const stationId = action.meta.arg;
        state[stationId] = { ...state[stationId], vitals: action.payload };
      })
      .addCase(fetchStationVitals.rejected, (state, action) => {
        console.error('fetchStationVitals rejected:', state, action);
      })
      .addCase(fetchStationTideCalendar.fulfilled, (state, action) => {
      const { stationId, unit } = action.meta.arg;
        state[stationId] = {
          ...state[stationId],
          tideCalendar: { ...state[stationId]?.tideCalendar, [unit]: action.payload },
        };
      })
      .addCase(fetchStationTideCalendar.rejected, (state, action) => {
        console.error('fetchStationTideCalendar rejected:', state, action);
      })
      .addCase(fetchStationTideCalendarThreshold.fulfilled, (state, action) => {
      const { stationId } = action.meta.arg;

      let threshold;
      if (action.payload === null) {
        threshold = null;
      } else {
        const defaultUnit = action.payload.unit;
        const defaultThreshold = action.payload.level;
        const isLow = action.payload.low;

          threshold = { isLow };  // Include isLow in the threshold object

          // Perform unit conversion based on the default unit
        if (defaultUnit === unitStandards.metric) {
          threshold[unitStandards.metric] = defaultThreshold;
          threshold[unitStandards.us] = defaultThreshold * 3.28084;
        } else if (defaultUnit === unitStandards.us) {
          threshold[unitStandards.metric] = defaultThreshold / 3.28084;
          threshold[unitStandards.us] = defaultThreshold;
        }
      }

        // Update the state (using the spread operator for immutability)
        state[stationId] = {
          ...state[stationId],
          tideCalendar: { ...state[stationId]?.tideCalendar, threshold },
        };
      })
      .addCase(fetchStationTideCalendarThreshold.rejected, (state, action) => {
        console.error('fetchStationTideCalendarThreshold rejected:', state, action);
      });
  },
});

export default stationListSlice.reducer;
