import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallbackComponent from '../ErrorBoundaryFallbackComponent';

const localizer = momentLocalizer(moment);

const TideCalendarComponent = ({ data, ...rest }) => {
  return (
    <Calendar
      localizer={localizer}
      defaultView="week"
      events={data}
      {...rest}
    />
  );
};

export default withErrorBoundary(TideCalendarComponent, {
  FallbackComponent: ErrorBoundaryFallbackComponent,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
  },
});
