import React from 'react';
import { Table } from 'semantic-ui-react';

const VitalsTableComponent = ({ vitals, ...rest }) => (
  <Table definition color="grey" unstackable {...rest}>
    <Table.Body>
      {vitals?.signalQuality && (
        <Table.Row>
          <Table.Cell>Signal Quality</Table.Cell>
          <Table.Cell>{vitals.signalQuality}</Table.Cell>
        </Table.Row>
      )}
      {vitals?.batteryCharge && (
        <Table.Row>
          <Table.Cell>Battery Charge</Table.Cell>
          <Table.Cell>{vitals.batteryCharge}</Table.Cell>
        </Table.Row>
      )}
      {/* {vitals?.status && (
        <Table.Row>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>{vitals.status}</Table.Cell>
        </Table.Row>
      )} */}
      {vitals?.cellularOperator && (
        <Table.Row>
          <Table.Cell>Communication</Table.Cell>
          <Table.Cell>{vitals.cellularOperator}</Table.Cell>
        </Table.Row>
      )}
      {vitals?.lastUpdated && (
        <Table.Row>
          <Table.Cell>Last Updated</Table.Cell>
          <Table.Cell>{vitals.lastUpdated}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </Table>
);

export default VitalsTableComponent;
