//Updated to use functions that return the same format as Firebase Realtime from Influxdb

import { firebaseUrl, functionUrl } from '../firebase-config';
import ICAL from 'ical.js';

const url = (path) => `${firebaseUrl}${path}`;
const furl = (path) => `${functionUrl}${path}`;

const jsonIfOk = (data) => {
  if (data.ok) return data.json();
  console.error(data.error);  // Log the error for debugging
  throw new Error(`HTTP error: ${data.status}`); // Throw an error to be caught later
};

export const getAllStations = () =>
  fetch(furl(`stationList?`)).then(jsonIfOk);

export const getStationDatum = async (stationId) => {
  const response = await fetch(
    url(`stations/${stationId}/data/datums.json?orderBy="$key"&limitToLast=1`)
  );

  let datum = await jsonIfOk(response);
  // Extract the current datum
  datum = datum[Object.keys(datum)[0]];

  return datum;
};

export const getStationMeasurements = async (stationId, start, end) => {
  let stationUrl;
  if (start && end) {
    stationUrl = furl(`getStationLevels?station_id=${stationId}&start=${Math.floor(start.getTime() / 1000)}&stop=${Math.floor(end.getTime() / 1000)}`);
  }

  const response = await fetch(stationUrl);
  let measurements = await jsonIfOk(response);
  return measurements;
};

export const getStationMetData = async (stationId, start, end) => {
  let stationUrl;
  if (start && end) {
    stationUrl = furl(`getMetData?station_id=${stationId}&start=${Math.floor(start.getTime() / 1000)}&stop=${Math.floor(end.getTime() / 1000)}`);
  }

  const response = await fetch(stationUrl);
  let measurements = await jsonIfOk(response);
  return measurements;
};

export const getStationForecast = async (stationId, start, end) => {
  let stationUrl;
  if (start && end) {
    stationUrl = furl(`getDetailedForecast?station_id=${stationId}&start=${Math.floor(start.getTime() / 1000)}&stop=${Math.floor(end.getTime() / 1000)}`);
  }

  const response = await fetch(stationUrl);
  let measurements = await jsonIfOk(response);
  return measurements;
};

export const getStationForecastHighsAndLows = async (stationId, start, end = new Date()) => {
  let highsUrl, lowsUrl;
  if (start && end) {
    const startTime = start instanceof Date ? start.toISOString() : start;
    const endTime = end instanceof Date ? end.toISOString() : end;
    highsUrl = furl(`getHighs?station_id=${stationId}&start=${startTime}&stop=${endTime}`);
    lowsUrl = furl(`getLows?station_id=${stationId}&start=${startTime}&stop=${endTime}`);
  } else {
    const year = new Date().getFullYear();
    const startOfYear = new Date(year - 1, 11, 31).toISOString(); // 1 day before the beginning of the year
    const endOfYear = new Date(year + 1, 0, 1).toISOString(); // 1 day after the end of the year
    highsUrl = furl(`getHighs?station_id=${stationId}&start=${startOfYear}&stop=${endOfYear}`);
    lowsUrl = furl(`getLows?station_id=${stationId}&start=${startOfYear}&stop=${endOfYear}`);
  }

  const response = await Promise.all([fetch(highsUrl), fetch(lowsUrl)]);
  const [forecastHighs, forecastLows] = await Promise.all(response.map(jsonIfOk));
  return { forecastHighs, forecastLows };
};

export const getStationStatistics = async (stationId) => {
  // need levelmllw, time
  const recordHighs = fetch(
    url(
      `stations/${stationId}/data/record_highs.json?orderBy="$key"&limitToLast=1`
    )
  );
  // need mllw, time
  const recordLows = fetch(
    url(
      `stations/${stationId}/data/record_lows.json?orderBy="$key"&limitToLast=1`
    )
  );

  const response = await Promise.all([recordHighs, recordLows]);
  let [high, low] = await Promise.all(response.map(jsonIfOk));
  high = high[Object.keys(high)[0]];
  low = low[Object.keys(low)[0]];

  return { high, low };
};

export const getStationMetadata = (stationId) => {
  return fetch(url(`station_list/${stationId}.json?`)).then(jsonIfOk);
};

// export const getStationVitals = (deviceId) => {
//   return fetch(
//     url(`vitals/${deviceId}.json?orderBy="$key"&limitToLast=1`)
//   ).then(jsonIfOk);
// };

export const getStationVitals = (stationId) => {
  return fetch(
    url(`stations/${stationId}/data/vitals.json`)
  ).then(jsonIfOk)
};

export const getStationTideCalendar = async (stationId, unit, isLow, threshold) => {
  //console.log({stationId, unit, isLow, threshold});
  const cloudFunctionUrl = furl(`getTideCalendar?station_id=${stationId}`); // Cloud Function URL

  try {
    const response = await fetch(cloudFunctionUrl);
    // Cloud Function successful, parse the ICS data
    const icsData = await response.text();
    //console.log("Raw ICS data from Cloud Function:", icsData);
    const jcalData = ICAL.parse(icsData);
    return jcalData[2].map((event) => {
      const eventInfo = event[1];
      return {
        title: eventInfo[0][3],
        start: eventInfo[1][3],
        end: eventInfo[2][3],
      };
    });
  } catch (error) {
    // Handle fetch errors (network issues, etc.)
    console.error("Error fetching tide calendar:", error);
    return null;
  }
};

export const getStationTideThreshold = (stationId) => {
  return fetch(
    url(`stations/${stationId}/data/tide_calendar/threshold.json`)
  ).then(jsonIfOk);
};
