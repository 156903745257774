import { connect } from 'react-redux';
import {
  fetchStationMeasurements,
  fetchStationDatum,
  fetchStationMetadata,
  fetchStationForecast,
  fetchStationForecastHighsAndLows,
  fetchStationStatistics,
  fetchStationVitals,
  fetchStationMetData,
  fetchStationTideCalendar,
  fetchStationTideCalendarThreshold,
} from '../../reducers/stationSlice';
import { units as unitSlice } from '../../reducers/unitSlice';
import StationPageComponent from '../pages/StationPageComponent';
import convert from 'convert-units';
import { useParams } from 'react-router-dom';


const mapStateToProps = (state, ownProps) => {
  const units = state.units;
  const stationId = ownProps.routeParams?.stationId;
  
  if (!stationId || !state.stations[stationId]) {
    return { units, stationId };
  }
  if (state.stations[stationId]) {
    let {
      measurements,
      met,
      forecast,
      forecastHighs,
      forecastLows,
      metadata,
      statistics,
      vitals,
      datum,
      tideCalendar,
    } = state.stations[stationId];

    // ----- measured data
    let actual = [];
    if (measurements && datum) {
      // fix 'read only' problem
      measurements = JSON.parse(JSON.stringify(measurements));
      // Loop through the object and adjust the levelmllw for the chart
      for (const key in measurements) {
        if (measurements.hasOwnProperty(key)) {
          measurements[key].levelmllw =
            measurements[key].levelmllw + measurements[key].mllw - datum.mllw;
        }
      }

      actual = Object.entries(measurements).map(([key, value]) => [
        key * 1000,
        Number(
          convert(value.levelmllw).from('m').to(units.length.value).toFixed(2)
        ),
      ]);
    }

    const metData = met
      ? {
          windSpeed: [],
          windDir: [],
          gust: [],
          airTemp: [],
          waterTemp: [],
          humidity: [],
          pressure: [],
        }
      : undefined;
    if (metData) {
      for (const [key, value] of Object.entries(met)) {
        if (!isNaN(value.wind_speed) && !isNaN(value.wind_direction))
          metData.windDir.push({
            x: key * 1000,
            value: Number(
              convert(value.wind_speed)
                .from('m/s')
                .to(units.speed.value)
                .toFixed(2)
            ),
            direction: value.wind_direction,
          });
        if (!isNaN(value.wind_speed))
          metData.windSpeed.push([
            key * 1000,
            Number(
              convert(value.wind_speed)
                .from('m/s')
                .to(units.speed.value)
                .toFixed(2)
            ),
          ]);
        if (!isNaN(value.wind_gust))
          metData.gust.push([
            key * 1000,
            Number(
              convert(value.wind_gust)
                .from('m/s')
                .to(units.speed.value)
                .toFixed(2)
            ),
          ]);
        if (!isNaN(value.air_temp))
          metData.airTemp.push([
            key * 1000,
            Number(
              convert(value.air_temp)
                .from('C')
                .to(units.temperature.value)
                .toFixed(1)
            ),
          ]);
        if (!isNaN(value.water_temp))
          metData.waterTemp.push([
            key * 1000,
            Number(
              convert(value.water_temp)
                .from('C')
                .to(units.temperature.value)
                .toFixed(1)
            ),
          ]);
        if (!isNaN(value.humidity))
          metData.humidity.push([
            key * 1000,
            Number(value.humidity.toFixed(0)),
          ]);
        if (!isNaN(value.pressure))
          metData.pressure.push([
            key * 1000,
            Number(
              convert(value.pressure)
                .from('hPa')
                .to(units.pressure.value)
                .toFixed(2)
            ),
          ]);
      }
    }

    const latestMeasurementTime = measurements
      ? Object.keys(measurements).reduce((highest, current) =>
          current > highest ? current : highest
        )
      : undefined;
    const latestMeasurement = measurements
      ? {
          time: latestMeasurementTime,
          value: Number(
            convert(measurements[latestMeasurementTime].levelmllw)
              .from('m')
              .to(units.length.value)
              .toFixed(2)
          ),
        }
      : undefined;

    const latestMetTime =
      met && Object.keys(met).length > 0
        ? Object.keys(met).reduce((highest, current) =>
            current > highest ? current : highest
          )
        : undefined;
    const latestMetData = latestMetTime
      ? {
          time: latestMetTime,
        }
      : undefined;
    if (latestMetData) {
      const latestMetRaw = met[latestMetTime];
      if (!isNaN(latestMetRaw.wind_speed))
        latestMetData.wind_speed = Number(
          convert(latestMetRaw.wind_speed)
            .from('m/s')
            .to(units.speed.value)
            .toFixed(2)
        );
      if (!isNaN(latestMetRaw.wind_gust))
        latestMetData.wind_gust = Number(
          convert(latestMetRaw.wind_gust)
            .from('m/s')
            .to(units.speed.value)
            .toFixed(2)
        );
      if (!isNaN(latestMetRaw.air_temp))
        latestMetData.air_temp = Number(
          convert(latestMetRaw.air_temp)
            .from('C')
            .to(units.temperature.value)
            .toFixed(1)
        );
      if (!isNaN(latestMetRaw.water_temp))
        latestMetData.water_temp = Number(
          convert(latestMetRaw.water_temp)
            .from('C')
            .to(units.temperature.value)
            .toFixed(1)
        );
      if (!isNaN(latestMetRaw.humidity))
        latestMetData.humidity = Number(latestMetRaw.humidity.toFixed(0));
      if (!isNaN(latestMetRaw.pressure))
        latestMetData.pressure = Number(
          convert(latestMetRaw.pressure)
            .from('hPa')
            .to(units.pressure.value)
            .toFixed(0)
        );
      if (!isNaN(latestMetRaw.wind_direction))
        latestMetData.wind_direction = Number(
          latestMetRaw.wind_direction.toFixed(0)
        );
    }

    // ----- forecast data
    // full forecast
    const forecastData = forecast
      ? Object.entries(forecast).map(([key, value]) => [
          key * 1000,
          // 'Forecast',
          Number(
            convert(value.levelmllw).from('m').to(units.length.value).toFixed(2)
          ),
        ])
      : [];

    // high tide forecast times
    const forecastHighData = forecastHighs
      ? Object.entries(forecastHighs).map(([key, value]) => ({
          value: 'Tide Level',
          identifier: 'H',
          tooltext: 'Forecast high tide',
          time: key,
          timeformat: '%s',
          series: {
            Type: 'Forecast',
          },
        }))
      : [];

    // low tide forecast times
    const forecastLowData = forecastLows
      ? Object.entries(forecastLows).map(([key, value]) => ({
          value: 'Tide Level',
          identifier: 'L',
          tooltext: 'Forecast low tide',
          time: key,
          timeformat: '%s',
          series: {
            Type: 'Forecast',
          },
        }))
      : [];

    // ----- data for today table and month table
    const tidesToday = [];
    const tidesTomorrow = [];
    let tableData;

    /* data structure for tableData:
        {
          [date]: {
            date: [date]
            highTides: {
              am: {
                time: [formatTime],
                value: [levelmllw]
              },
              pm: {
                time: [formatTime],
                value: [levelmllw]
              }
            },
            lowTides: {
              am: {
                time: [formatTime],
                value: [levelmllw]
              },
              pm: {
                time: [formatTime],
                value: [levelmllw]
              }
            }
          }
        }
    */

    if (forecastHighs) {
      tableData = {};
      for (const [key, value] of Object.entries(forecastHighs)) {
        const date = new Date(key * 1000);
        const ampm = date.getHours() >= 12 ? 'pm' : 'am';

        const day = new Date(key * 1000);
        day.setHours(0, 0, 0, 0);

        const data = {
          value: convert(value.levelmllw).from('m').to(units.length.value),
          time: date,
        };

        if (tableData[day]) {
          tableData[day].highTides[ampm] = data;
        } else {
          tableData[day] = {
            date: day,
            highTides: { [ampm]: data },
            lowTides: {},
          };
        }

        const today = new Date();
        if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          tidesToday.push({
            level: convert(value.levelmllw).from('m').to(units.length.value),
            time: key,
            type: 'high',
          });
        } else if (
          date.getDate() === today.getDate() + 1 &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          tidesTomorrow.push({
            level: convert(value.levelmllw).from('m').to(units.length.value),
            time: key,
            type: 'high',
          });
        }
      }
    }

    if (forecastLows) {
      for (const [key, value] of Object.entries(forecastLows)) {
        const date = new Date(key * 1000);
        const ampm = date.getHours() >= 12 ? 'pm' : 'am';

        const day = new Date(key * 1000);
        day.setHours(0, 0, 0, 0);

        const data = {
          value: convert(value.levelmllw).from('m').to(units.length.value),
          time: date,
        };

        if (tableData[day]) {
          tableData[day].lowTides[ampm] = data;
        } else {
          tableData[day] = {
            date: day,
            highTides: {},
            lowTides: { [ampm]: data },
          };
        }

        const today = new Date();
        if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          tidesToday.push({
            level: convert(value.levelmllw).from('m').to(units.length.value),
            time: key,
            type: 'low',
          });
        } else if (
          date.getDate() === today.getDate() + 1 &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          tidesTomorrow.push({
            level: convert(value.levelmllw).from('m').to(units.length.value),
            time: key,
            type: 'low',
          });
        }
      }
    }

    let convertedStatistics = {};
    if (statistics && datum) {
      statistics = {
        waterMax: {
          level: statistics.high.levelmllw + statistics.high.mllw - datum.mhhw,
          time: statistics.high.time,
        },
        waterMin: {
          level: statistics.low.levelmllw + statistics.low.mllw - datum.mllw,
          time: statistics.low.time,
        },
        msl: datum.msl - datum.mllw,
        mhhw: datum.mhhw - datum.mllw,
      };

      // fix 'read only' problem
      convertedStatistics = JSON.parse(JSON.stringify(statistics));
      if (
        !isNaN(convertedStatistics.waterMax?.level) &&
        !isNaN(convertedStatistics.waterMax?.time)
      ) {
        convertedStatistics.waterMax.level = Number(
          convert(convertedStatistics.waterMax.level)
            .from('m')
            .to(units.length.value)
            .toFixed(2)
        );
        convertedStatistics.waterMax.time = new Date(
          convertedStatistics.waterMax.time * 1000
        );
      }
      if (
        !isNaN(convertedStatistics.waterMin?.level) &&
        !isNaN(convertedStatistics.waterMin?.time)
      ) {
        convertedStatistics.waterMin.level = Number(
          convert(convertedStatistics.waterMin.level)
            .from('m')
            .to(units.length.value)
            .toFixed(2)
        );
        convertedStatistics.waterMin.time = new Date(
          convertedStatistics.waterMin.time * 1000
        );
      }
      if (!isNaN(convertedStatistics.msl)) {
        convertedStatistics.msl = Number(
          convert(convertedStatistics.msl)
            .from('m')
            .to(units.length.value)
            .toFixed(2)
        );
      }
      if (!isNaN(convertedStatistics.mhhw)) {
        convertedStatistics.mhhw = Number(
          convert(convertedStatistics.mhhw)
            .from('m')
            .to(units.length.value)
            .toFixed(2)
        );
      }
    }

    let formattedVitals;
    if (vitals) {
      formattedVitals = {
        signalQuality: vitals.quality ? `${Number(vitals.quality).toFixed(1)}%` : 'N/A',
        batteryCharge: vitals.charge ? `${Number(vitals.charge).toFixed(0)}%` : 'N/A',
        status: vitals.status || 'unknown',
        cellularOperator: vitals.communication || 'unknown',
        lastUpdated: vitals.last_updated 
          ? new Date(vitals.last_updated * 1000).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              timeZoneName: 'short'
            })
          : 'N/A'
      };
    }

    let unitTideCalendar;
    if (tideCalendar?.[units.standard]) {
      unitTideCalendar = tideCalendar[units.standard].map(
        ({ start, end, ...rest }) => ({
          start: new Date(start),
          end: new Date(end),
          ...rest,
        })
      );
    }

    return {
      stationId,
      measurements: actual,
      met: metData,
      latestMeasurement,
      latestMetData,
      forecast: forecastData,
      combinedMarkers: [...forecastHighData, ...forecastLowData],
      tidesToday,
      tidesTomorrow,
      tableData,
      metadata,
      units,
      statistics: convertedStatistics,
      vitals: formattedVitals,
      tideCalendar: unitTideCalendar,
      threshold: tideCalendar?.threshold,
    };
  } else {
    return { 
      units,
      stationId // Add stationId to the return object
    };
  }
};

const mapDispatchToProps = (dispatch) => ({
  getStationMeasurements: (stationId, start, end) => {
    // Ensure start and end are Date objects
    const startDate = new Date(start);
    const endDate = new Date(end);
    return dispatch(
      fetchStationMeasurements({
        stationId,
        start: startDate,
        end: endDate
      })
    );
  },
  getStationMetData: (stationId, start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return dispatch(
      fetchStationMetData({
        stationId,
        start: startDate,
        end: endDate
      })
    );
  },
  getStationForecast: (stationId, start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return dispatch(
      fetchStationForecast({
        stationId,
        start: startDate,
        end: endDate
      })
    );
  },
  getStationForecastHighsAndLows: (stationId) =>
    dispatch(fetchStationForecastHighsAndLows({ stationId })),
  getStationDatum: (stationId) =>
    dispatch(fetchStationDatum(stationId)),
  getStationMetadata: (stationId) =>
    dispatch(fetchStationMetadata(stationId)),
  getStationStatistics: (stationId) =>
    dispatch(fetchStationStatistics(stationId)),
  getStationVitals: (stationId) =>
    dispatch(fetchStationVitals(stationId)),
  getStationTideCalendar: (stationId, unit, threshold, isLow) => {
    //console.log('Calendar params:', { stationId, unit, threshold, isLow }); // Add logging
    return dispatch(
      fetchStationTideCalendar({
        stationId,
        unit,
        threshold,
        isLow
      })
    );
  },
  getStationTideThreshold: (stationId) =>
    dispatch(fetchStationTideCalendarThreshold({ stationId })),
  setUnit: (unit) => dispatch(unitSlice.actions.setStandard(unit))
});

// Create a proper function component wrapper
const WithParams = connect(
  mapStateToProps,
  mapDispatchToProps
)(StationPageComponent);

// Create the wrapper component that uses hooks
function StationPageWithParams(props) {
  const params = useParams();
  return <WithParams {...props} routeParams={params} />;
}

// Export the wrapper component
export default StationPageWithParams;
