import React from 'react';
import { Segment, Container, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterComponent = ({ yearPublished = 2018, ...rest }) => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter inverted attached="top" {...rest}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                © {year === yearPublished ? year : `${yearPublished}-${year}`} Sentient Things
              </div>
              <div>
                <Link to="/faq" style={{ color: '#ffffff' }}>
                  FAQ
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled(Segment)`
  margin-top: 0 !important;
  background: #115c97ff !important;
`;

export default FooterComponent;
