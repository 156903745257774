import React from 'react';
import { Header } from 'semantic-ui-react';

const LatestReadingStatComponent = ({ title, value, unit, time, ...rest }) => (
  <Header
    size="huge"
    style={{
      fontSize: '4em',
    }}
    {...rest}
  >
    <Header.Content style={{ lineHeight: '75%' }}>
      <span
        style={{
          fontSize: '0.5em',
          display: 'block',
          lineHeight: '90%',
          marginBottom: '0.35rem',
        }}
      >
        {title}
      </span>
      {isNaN(value) ? '—' : `${value}${unit}`}
    </Header.Content>
    <Header.Subheader style={{ marginTop: '0.75rem' }}>
      as of{' '}
      {new Date(time * 1000).toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
      })}
      <br />
      {new Date(time * 1000).toLocaleDateString()}
    </Header.Subheader>
  </Header>
);

export default LatestReadingStatComponent;
